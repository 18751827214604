import {
    NEW_CLIENT_STEP1_START, NEW_CLIENT_STEP1_SUCCESS, NEW_CLIENT_STEP1_FAIL,

    NEW_CLIENT_STEP2_START, NEW_CLIENT_STEP2_SUCCESS, NEW_CLIENT_STEP2_FAIL,

    NEW_CLIENT_STEP3_START, NEW_CLIENT_STEP3_SUCCESS, NEW_CLIENT_STEP3_FAIL,
} from './actionsType';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';
import { navigate } from 'gatsby';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export function NewClientStep1(formdataStep1, token, setValidated, setStep, step) {
    return (dispatch) => {
        dispatch(NewClientStep1Start())

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.post('/api/users/signup', formdataStep1, config)
            .then((res) => {
                let data = res.data;
                // console.log(data)
                setValidated(false)
                setStep(step + 1)
                dispatch(NewClientStep1Success(data))

                Swal.fire({
                    icon: 'info',
                    title: 'Información de usuario',
                    showCloseButton: true,
                    html:
                    `<b>Usuario:</b> ${data.user.name} ${data.user.lastname}<br/>` +
                    `<b>Email:</b> ${data.user.email}<br/>` +
                    `<b>Password:</b> ${data.password}`,
                })

                // Toast.fire({
                //     icon: 'success',
                //     title: 'Usuario creado!'
                // })
            })
            .catch(error => {
                dispatch(NewClientStep1Fail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error en los datos'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error, intente de nuevo'
                    })
                }
            })
    }
}

// -----------------------------------------------------------------------

export function NewClientStep2(formdataStep2, token, setValidated, setStep, step) {
    return (dispatch) => {
        dispatch(NewClientStep2Start())

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.post('/api/space', formdataStep2, config)
            .then((res) => {
                let data = res.data;
                // console.log(data)
                setValidated(false)
                setStep(step + 1)
                dispatch(NewClientStep2Success(data))
                Toast.fire({
                    icon: 'success',
                    title: 'Espacio creado'
                })
            })
            .catch(error => {
                dispatch(NewClientStep2Fail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error en los datos'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error, intente de nuevo'
                    })
                }
            })
    }
}
// -----------------------------------------------------------------------
export function NewClientStep3(body, token, setValidated, setStep, setFormDataStep1, setFormDataStep2) {
    return (dispatch) => {
        dispatch(NewClientStep3Start())

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.post('/api/payment', body, config)
            .then((res) => {
                let data = res.data;
                // console.log(data)
                setValidated(false)
                setStep(1)
                // setFormDataStep1({
                //     name: '',
                //     email: '',
                //     lastname: '',
                // })
                // setFormDataStep2({
                //     // client: '', ---> se obtiene del step 1
                //     lote: '',
                //     mensura: '',
                //     barrio: '',
                //     divisa: '',
                //     vencimiento: '',
                // })
                dispatch(NewClientStep3Success(data))
                Toast.fire({
                    icon: 'success',
                    title: 'Cuotas creada'
                })
            })
            .catch(error => {
                dispatch(NewClientStep3Fail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error en los datos'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error, intente de nuevo'
                    })
                }
            })
    }
}


export const NewClientStep1Start = () => {
    return {
        type: NEW_CLIENT_STEP1_START
    };
};

export const NewClientStep1Success = (data) => ({
    type: NEW_CLIENT_STEP1_SUCCESS,
    payload: data
})

export const NewClientStep1Fail = () => ({
    type: NEW_CLIENT_STEP1_FAIL,
})
// -===========================================
export const NewClientStep2Start = () => {
    return {
        type: NEW_CLIENT_STEP2_START
    };
};

export const NewClientStep2Success = (data) => ({
    type: NEW_CLIENT_STEP2_SUCCESS,
    payload: data
})

export const NewClientStep2Fail = () => ({
    type: NEW_CLIENT_STEP2_FAIL,
})
// -===========================================
export const NewClientStep3Start = () => {
    return {
        type: NEW_CLIENT_STEP3_START
    };
};

export const NewClientStep3Success = (data) => ({
    type: NEW_CLIENT_STEP3_SUCCESS,
    payload: data
})

export const NewClientStep3Fail = () => ({
    type: NEW_CLIENT_STEP3_FAIL,
})