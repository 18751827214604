import React from 'react';
import { Link } from 'gatsby';
import { Nav } from 'react-bootstrap';
import styled from "styled-components";
import { useSelector } from 'react-redux';

const ContainerNav = styled(Nav)`
    .active {
        color: #2E2A48 !important;
        background-color: #ffffff !important;
    }
    .nav-link{
        color: #2E2A48;
    }
`
const NavbarDashboard = () => {
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    const rol = useSelector(state => state.auth.role)

    return (
        <ContainerNav className="mt-4" variant="pills">
            {rol === 'superAdmin' ?
                <React.Fragment>
                    <Nav.Item className="w-100 text-center">
                        <Link className={`nav-link ${url.includes('/dashboard/clientes') ? "active" : ""}`} to="/dashboard/clientes">Clientes</Link>
                    </Nav.Item>
                    <Nav.Item className="w-100 text-center">
                        <Link className={`nav-link ${url.includes('/dashboard/vencimientos') ? "active" : ""}`} to="/dashboard/vencimientos">Vencimientos</Link>
                    </Nav.Item>
                    <Nav.Item className="w-100 text-center">
                        <Link className={`nav-link ${url.includes('/dashboard/nuevo-cliente') ? "active" : ""}`} to="/dashboard/nuevo-cliente">Nuevo cliente</Link>
                    </Nav.Item>
                </React.Fragment>
                :
                <React.Fragment>
                    <Nav.Item className="w-100 text-center">
                        <Link className={`nav-link ${url.includes('/dashboard/usuario/cuotas') ? "active" : ""}`} to="/dashboard/usuario/cuotas">Cuotas</Link>
                    </Nav.Item>
                    <Nav.Item className="w-100 text-center">
                        <Link className={`nav-link ${url.includes('/dashboard/usuario/vencimientos') ? "active" : ""}`} to="/dashboard/usuario/vencimientos">Vencimientos</Link>
                    </Nav.Item>
                </React.Fragment>
            }
        </ContainerNav>
    );
}

export default NavbarDashboard;