import React from 'react';
import SEO from '../../structure/seo';
import Layout from '../../structure/layout';
import DashboardStructure from '../../structure/general/DashboardStructure';
import Index from '../../structure/pages/dashboard/new-client';


const DashboardNewClient = () => {
    return (
        <Layout shadowNav={false} noHeader noFooter mainBg="#E1E5EA">
            <SEO title={'Dashboard - Nuevo Cliente'} />
            <DashboardStructure>
                <Index />
            </DashboardStructure>
        </Layout>
    );
}

export default DashboardNewClient;