import React, { useState } from 'react';
//Components

//Bootstrap
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
//CSS
import styled from 'styled-components';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import Navbar from './NavbarDashboard';

import { LogOutAction } from '../../redux/actions/logout';
import { useDispatch, useSelector } from 'react-redux';

const Section = styled(Container)`
    margin-top:3rem !important;
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.25));
    /* overflow-y:hidden; */
	overflow-x:hidden;
	background: #F5F5F5;
	border-radius:15px;
	/* min-height:85rem; */
	/* height: 100%; */
	/* max-height:100%; */
	/* width:100%; */
	/* max-width:1820px; */
	/* margin:auto; */
	position:relative;
	transition:all .2s !important;
    height: 88vh;

	@media (max-width:1300px){
		.main{
			width:93% !important;
            height: 88vh;
		}
	}
	@media (max-width:1200px){
		.main{
			width:100% !important;
		}
		.openMenu{
			display:flex;
		}
	}
	@media (min-width:1300px){
		.closeNotifications{
			display:none;
		}
		.openNotifications{
			display:none;
		}
	}
	@media (min-width:1200px){
		.closeMenu{
			display:none;
		}
		.openMenu{
			display:none;
		}
	}
	.menu{
		/* border-right: 1px solid #2E3336; */
        background:#F1F5F9;
		transition:all .2s !important;
		z-index:10;
		@media (max-width:1200px){
			position:fixed;
			top:0;
			left:0;
			bottom:0;
			width:10rem;
			background-color:#fff;
			transition:all .2s !important;
			padding:0 0rem !important;
		}
	}
    button.btn-secondary, .btn.btn-link.dx-g-bs4-table-edit-command-cell{
        border-radius: 10px;
        background-color: #1D2146 !important;
        border-color: #1D2146 !important;
        padding:10px 50px;
        cursor:pointer;
        z-index: 999 !important;
    }
	.nav-tabs .nav-link{color:#000 !important;}
`;

const ContainerMenuAside = styled.div`
	width:12rem;
	padding:0 1rem;
	position:relative;
	transition:all .2s !important;
    background: #F1F5F9;
`


const DashboardStructure = ({ children }) => {
	const token = useSelector(state => state.auth.token)
	//DISPATCH
	const dispatch = useDispatch();
	//ACTIONS
	const LogOutFn = (token) => dispatch(LogOutAction(token));

	const [menu, setMenu] = useState(false)

	const logout = () => {
		LogOutFn(token)
	}

	return (
		<Section className="px-0">
			<div className="h-100 d-flex child">
				<ContainerMenuAside css={css`@media (max-width:1200px){transform:${menu ? "translateX(0%)" : "translateX(-99.99%)"}; z-index:99;}`} className="menu d-flex align-items-start flex-column pb-4">
					<div className="mb-auto">
						<Button variant="secondary" className="openMenu px-3 py-2" onClick={() => setMenu(false)}>
							<StaticImage src="../../images/delete-icon.svg" width={30} height={30} alt="borrar" />
						</Button>
						<StaticImage src="../../images/villanautica-logo.png" width={150} height={220} alt="Logo" className="" />
						<Navbar />
					</div>
					<div className="w-100">
						<Button onClick={() => logout()} className="w-100" variant="secondary" css={css`border-radius:5px;`}>
							Salir
						</Button>
					</div>
				</ContainerMenuAside>

				<div className={`main w-100`} css={css`background:#fff;`}>
					<Container className="d-xl-none" fluid css={css`border-bottom: 1px solid #2E3336;`}>
						<Row className="justify-content-between align-items-center py-4 d-flex px-3 w-100 m-0">
							<Col lg={6} md={6} sm={12} className="d-flex align-items-center">
								<Button variant="secondary" className="openMenu px-3 py-2" onClick={() => setMenu(true)}>
									<StaticImage src="../../images/list-icon.svg" width={20} height={20} alt="menu" />
								</Button>
							</Col>
						</Row>
					</Container>
					<div className="m-5" css={css`position:relative;`}>
						{children}
					</div>
				</div>
			</div>

		</Section>
	);
}

export default DashboardStructure;