import React, { useState } from 'react';
import { Col, Container, Row, Form, Button, Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import clienteAxios from '../../../config/axios';
import { NewClientStep1, NewClientStep2, NewClientStep3 } from '../../../redux/actions/newClient';


const Title = styled.h2`
    font-size: 1.5em !important;
`
const ButtonDelete = styled(Button)`
    padding: 6px 10px !important;
    background: #bb2d3b !important;
    border-color: #bb2d3b !important;
`

// EXTENSION DE COMPONENTE STEP 2-----------------------------
const ClientsQuotes = ({ onChange, onRemove, concept, price, expiration }) => {
    return (
        <Row className="align-items-end">
            <Form.Group as={Col} md="4" controlId="validationCustom03" className="mt-3">
                <Form.Label>COMCEPTO</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Concepto"
                    required
                    onChange={e => onChange("concept", e.target.value)}
                    value={concept}
                    style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationCustom03" className="mt-3">
                <Form.Label>MONTO</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Monto"
                    required
                    onChange={e => onChange("price", e.target.value)}
                    value={price}
                    style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom03" className="mt-3">
                <Form.Label>VENCIMIENTO</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Vencimiento"
                    required
                    onChange={e => onChange("expiration", e.target.value)}
                    value={expiration}
                    style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                />
            </Form.Group>
            <Col md={1}>
                <ButtonDelete className="btn-danger" onClick={onRemove}>X</ButtonDelete>
            </Col>
        </Row>
    );
}
// FIN EXTENSION DE COMPONENTE STEP 2-----------------------------


const NewClient = () => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const token = useSelector(state => state.auth.token)
    const client = useSelector(state => state.newClient.dataStep1)
    const space = useSelector(state => state.newClient.dataStep2)
    const [step, setStep] = useState(1)
    const [validated, setValidated] = useState(false);
    const [manual, setManual] = useState(true);

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const NewClientStep1Fn = (formdataStep1, token, setValidated, setStep, step) => dispatch(NewClientStep1(formdataStep1, token, setValidated, setStep, step));
    const NewClientStep2Fn = (formdataStep2, token, setValidated, setStep, step) => dispatch(NewClientStep2(formdataStep2, token, setValidated, setStep, step));
    const NewClientStep3Fn = (body, token, setValidated, setStep, step, setFormDataStep1, setFormDataStep2) => dispatch(NewClientStep3(body, token, setValidated, setStep, step, setFormDataStep1, setFormDataStep2));
    // validate form
    const handleStep = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true) {
            if (step === 1) {
                formData()
            } else if (step === 2) {
                formData2()
            }
        }
    };

    // -------- STEP 1----------------------
    const [formdataStep1, setFormDataStep1] = useState({
        name: '',
        email: '',
        lastname: '',
    })
    const { name, email, lastname } = formdataStep1;
    const formData = () => {
        NewClientStep1Fn(formdataStep1, token, setValidated, setStep, step)
    }
    const readFormDataStep1 = (e) => {
        setFormDataStep1({
            ...formdataStep1,
            [e.target.name]: e.target.value
        })
    }
    // -------- STEP 2----------------------
    const [formdataStep2, setFormDataStep2] = useState({
        // client: '', ---> se obtiene del step 1
        lote: '',
        mensura: '',
        barrio: '',
        divisa: '',
        // vencimiento: '',
    })
    const { lote, mensura, barrio, divisa, vencimiento } = formdataStep2;

    const formData2 = () => {
        formdataStep2.client = client.user._id
        // console.log(formdataStep2, token, setValidated, setStep, step)
        NewClientStep2Fn(formdataStep2, token, setValidated, setStep, step)
    }

    const readFormDataStep2 = (e) => {
        setFormDataStep2({
            ...formdataStep2,
            [e.target.name]: e.target.value
        })
    }

    // ------------- STEP 3 ------------------
    const [excel, setExcelFile] = useState("")

    const [rows, setRows] = useState([defaultState]);

    const defaultState = {
        price: "",
        concept: "",
        expiration: ""
    };

    const handleOnChange = (index, name, value) => {
        const copyRows = [...rows];
        copyRows[index] = {
            ...copyRows[index],
            [name]: value
        };
        setRows(copyRows);
    };

    const handleOnAdd = () => {
        setRows(rows.concat(defaultState));
    };

    const handleOnRemove = index => {
        const copyRows = [...rows];
        copyRows.splice(index, 1);
        setRows(copyRows);
    };

    const handleSaveFinish = () => {
        let body = {
            payments: rows,
            spaceId: space._id
        }

        // console.log(body)
        NewClientStep3Fn(body, token, setValidated, setStep, step, setFormDataStep1, setFormDataStep2)
    }

    const uploadExcel = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('excelFile', excel);
        formData.append("spaceId", space._id);

        clienteAxios.post(`/api/payment/excel`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                // console.log(res.data)
                setValidated(false)
                setStep(1)
                setFormDataStep1({
                    name: '',
                    email: '',
                    lastname: '',
                })
                setFormDataStep2({
                    lote: '',
                    mensura: '',
                    barrio: '',
                    divisa: '',
                    vencimiento: '',
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Cuotas creada'
                })
            })
            .catch(err => {
                // console.log(err)
            })
    }


    return (
        <div>
            <Container>
                <Row>
                    <Col lg={8} md={12} xs={12}>
                        <Row className="mb-3">
                            {step === 1 ?
                                <React.Fragment>
                                    <Title>Nuevo cliente | Usuario</Title>
                                    <Form noValidate validated={validated} onSubmit={handleStep}>
                                        <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                            <Form.Label>NOMBRE</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nombre"
                                                required
                                                name="name"
                                                onChange={readFormDataStep1}
                                                value={name}
                                                style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Nombre requerido
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                            <Form.Label>APELLIDO</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Apellido"
                                                required
                                                name="lastname"
                                                onChange={readFormDataStep1}
                                                value={lastname}
                                                style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Apellido requerido.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                            <Form.Label>EMAIL</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                required
                                                name="email"
                                                onChange={readFormDataStep1}
                                                value={email}
                                                style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Email requerido
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <button type="submit" className="btn btn-secondary mt-4 w-100">CONTINUAR</button>
                                    </Form>
                                </React.Fragment>
                                : step === 2 ?
                                    <React.Fragment>
                                        <Title>Nuevo cliente | Espacio</Title>
                                        <Form noValidate validated={validated} onSubmit={handleStep}>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                                    <Form.Label>LOTE</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Lote"
                                                        required
                                                        name="lote"
                                                        onChange={readFormDataStep2}
                                                        value={lote}
                                                        style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Lote requerido
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" controlId="validationCustom03" className="mt-3">
                                                    <Form.Label>MENSURA</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Mensura"
                                                        required
                                                        name="mensura"
                                                        onChange={readFormDataStep2}
                                                        value={mensura}
                                                        style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Mensura requerida.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" controlId="validationCustom03" className="mt-3">
                                                    <Form.Label>BARRIO</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Barrio"
                                                        required
                                                        name="barrio"
                                                        onChange={readFormDataStep2}
                                                        value={barrio}
                                                        style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Barrio requerido
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                                    <Form.Label>DIVISA</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        placeholder="Divisa"
                                                        required
                                                        name="divisa"
                                                        onChange={readFormDataStep2}
                                                        value={divisa}
                                                        style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                                    >
                                                        <option value="">Seleccione una opción</option>
                                                        <option value="Dolares">Dólares</option>
                                                        <option value="Nafta">Nafta</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        Divisa requerida
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                {/* <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                                    <Form.Label>VENCIMIENTO</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Vencimiento"
                                                        required
                                                        name="vencimiento"
                                                        onChange={readFormDataStep2}
                                                        value={vencimiento}
                                                        style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Vencimiento requerido
                                                    </Form.Control.Feedback>
                                                </Form.Group> */}
                                            </Row>

                                            <button type="submit" className="btn btn-secondary mt-4" w-100>CONTINUAR</button>
                                        </Form>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <Title>Nuevo Cliente | Gestión de cuotas</Title>
                                        <Tabs defaultActiveKey="manual" className="mb-3 mt-5">
                                            <Tab eventKey="manual" title="Carga manual">
                                                <div>
                                                    <div className="App">
                                                        {rows.map((row, index) => (
                                                            <ClientsQuotes
                                                                {...row}
                                                                onChange={(name, value) => handleOnChange(index, name, value)}
                                                                onRemove={() => handleOnRemove(index)}
                                                                key={index}
                                                            />
                                                        ))}
                                                        <Button className="mt-4" variant="secondary" onClick={handleOnAdd}>Agregar</Button>
                                                    </div>
                                                    <Row>
                                                        <Col><button onClick={() => handleSaveFinish()} className="btn btn-secondary mt-4 w-100">Guardar</button></Col>
                                                    </Row>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="excel" title="Carga via exel">
                                                <input type="file" accept=".xlsx, .xls, .csv" onChange={(e) => setExcelFile(e.target.files[0])} />
                                                <Row>
                                                    <Col><button onClick={(e) => uploadExcel(e)} className="btn btn-secondary mt-4 w-100">Guardar</button></Col>
                                                </Row>
                                            </Tab>
                                        </Tabs>
                                    </React.Fragment>
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default NewClient;
