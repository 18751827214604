import {
    LOGOUT_START,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,


} from './actionsType';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';
import { navigate } from 'gatsby';


export function LogOutAction(token) {
    return (dispatch) => {
        dispatch(logoutStart())
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.post('/api/users/signout', {}, config)
            .then((res) => {
                dispatch(logoutSuccess())
                navigate('/login')
            })
            .catch(error => {
                dispatch(logoutFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error, intente de nuevo'
                    })
                }
            })
    }
}
export const logoutStart = () => {
    return {
        type: LOGOUT_START
    };
};

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS
})

export const logoutFail = () => ({
    type: LOGOUT_FAIL,
})